<!-- LoginModal.vue -->
<template>
  <div v-if="show" class="modal-mask">
    <div v-if="!showChoose" class="modal-container">
      <div class="login_header">
        <span class="login_header_context--item1">手机号快捷登录</span>
        <span class="login_header_context--item2">验证即登录，未注册将自动创建工匠AI账号</span>
      </div>
      <div class="login_divide">
        <div class="login_divide-context"></div>
      </div>
      <div class="login_form">
        <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="100px">
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="loginForm.phone" placeholder="请输入手机号" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="验证码" class="verification_code" prop="captcha" label-width="100px">
            <el-input v-model="loginForm.captcha" placeholder="请输入验证码"></el-input>
            <img class="verification_code--pic" :src="captchaImg" alt="">
            <span class="verification_code--flush-code" @click="changeValidCode()">换一张</span>
          </el-form-item>
          <el-form-item label="短信验证码" prop="phoneCode" label-width="100px">
            <el-input v-model="loginForm.phoneCode" placeholder="请输入短信验证码" class="phoneCodeV">
              <template slot="append">
                <div @click="getCode" :class="messageSendTime === 0? 'phoneCode enable': 'phoneCode disable'">
                  <span>获取验证码{{ messageSendTime === 0 ? '' : '(' + messageSendTime + ')' }}</span>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <div class="login_cert">
            <el-checkbox v-model="checked">
            </el-checkbox>
            <span style="margin-left: 4px">已阅读并同意</span>
            <span class="href_text" @click="commonData(1)">《隐私协议》</span>
            <span class="href_text" @click="commonData(5)">《用户协议》</span>
          </div>
          <div class="login_btn" @click="submit()">
            登录
          </div>
          <el-dialog
              top="5vh"
              :modal="false"
              :visible.sync="dialogVisible"
              width="40%">
            <div style="width: 100%;height: 75vh;overflow: auto">
              <div v-html="vHtmlContent"></div>
            </div>
          </el-dialog>
        </el-form>
      </div>
      <div class="close_btn">
        <i class="el-icon-close" @click="closeModal"></i>
      </div>
    </div>
    <div v-if="showChoose" class="choose-body">
      <div class="choose-body--title">
        <span>请选择您的角色</span>
      </div>
      <div class="choose-body--content">
        <div :class="['choose-body--content--item', chooseIdentity === 2 ? 'choose-item' : 'normal-item']"
             @click="changeIdentity(2)">
          <div class="left-item">
            <img src="@/assets/login/hrAvatar.png" alt="">
          </div>
          <div class="right-item">
            <span>我要招人</span>
            <span>企业HR、老板</span>
          </div>
        </div>
        <div :class="['choose-body--content--item', chooseIdentity === 1 ? 'choose-item' : 'normal-item']"
             @click="changeIdentity(1)">
          <div class="left-item">
            <img src="@/assets/login/userAvatar.png" alt="">
          </div>
          <div class="right-item">
            <span>我要找活</span>
            <span>工人、员工</span>
          </div>
        </div>
      </div>
      <div class="chooseBtn-box">
        <div class="chooseBtn-item" @click="closeModal">取消</div>
        <div class="chooseBtn-item" @click="submitIdentity()">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import {loginCodeApi} from '@/api/login'
import rsaService from '@/utils/RSA'
import {setToken} from '@/utils/auth'
import store from '@/store'
import axios from 'axios'
import {updateUserIdentityApi, userInfoApi} from "@/api/user";
import {getCommonConfigApi} from "@/api/home";
import router from "@/router";

export default {
  name: 'LoginModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入11位手机号'));
      } else {
        //11位手机号正则
        const reg = /^1[3-9]\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    var checkCaptcha = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入验证码'));
      }
    }
    var checkPhoneCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入短信验证码'));
      } else {
        const reg = /^[0-9]{4}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('短信验证码错误'));
        }
      }
    }
    return {
      showChoose: false,
      chooseIdentity: 2,
      dialogVisible: false,
      vHtmlContent: '',
      rules: {
        phone: [{validator: checkPhone, trigger: 'blur'}],
        captcha: [{message: '请输入验证码', trigger: 'blur'}],
        phoneCode: [{validator: checkPhoneCode, trigger: 'blur'}],
      },
      captchaImg: '',
      messageSendTime: 0,
      loginForm: {
        phone: '',
        phoneCode: '',
        longitude: '',
        latitude: '',
        loginType: 1,
        captcha: '',
        uuid: '',
      },
      //同意协议
      checked: false,
      //验证码
      validCode: '',
      username: '',
      password: '',
      timer: null
    };
  },
  mounted
      () {
    this.changeValidCode()
  }
  ,
  methods: {
    changeIdentity(item) {
      this.chooseIdentity = item
    },
    submitIdentity() {
      updateUserIdentityApi({identity: this.chooseIdentity}).then(res => {
        store.commit('SET_IDENTITY', this.chooseIdentity)
        this.closeModal()
      }).catch(() => {
      })
    },
    commonData(type) {
      getCommonConfigApi({sourceType: type}).then(res => {
        this.vHtmlContent = res.data.content
        this.dialogVisible = true
      }).catch(() => {
      })
    },
    getUserInfo() {
      userInfoApi().then(res => {
        this.userInfo = res.data
        store.commit('SET_IDENTITY', this.userInfo.identity)
        store.commit('SET_USER', this.userInfo)
        store.commit('SET_NICKNAME', this.userInfo.nickName)
      }).catch(() => {

      })
    },
    // 提交表单
    submit() {
      console.log()
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          //执行登录请求
          if (this.checked) {
            this.handleLogin()
          } else {
            this.$message({
              message: '请阅读并同意隐私协议及用户协议',
              type: 'warning',
              customClass: 'zZindex',
            });
          }
        } else {
          /*this.$message({
            message: '请输入完整信息',
            type: 'warning',
            customClass: 'zZindex',
          });*/
        }
      })
    },
    //获取图片验证码
    changeValidCode() {
      axios.get('/api/code').then(res => {
        this.loginForm.uuid = res.data.uuid
        this.captchaImg = 'data:image/gif;base64,' + res.data.img
      }).catch(() => {

      })
    },
    //正则判断手机号码
    checkPhone(phone) {
      const reg = /^1[3-9]\d{9}$/;
      return reg.test(phone);
    },
    // 获取短信验证码
    getCode() {
      if (this.messageSendTime === 0) {
        if (!this.checkPhone(this.loginForm.phone)) {
          this.$message({
            message: '请输入正确的11位手机号码',
            type: 'warning',
            customClass: 'zZindex',
          });
        } else if (this.loginForm.captcha === '') {
          this.$message({
            message: '请输入验证码',
            type: 'warning',
            customClass: 'zZindex',
          });
        } else {
          axios.post('/api/crm/sms/loginCode', {
            phone: this.loginForm.phone,
            captcha: this.loginForm.captcha,
            uuid: this.loginForm.uuid
          }).then(res => {
            if (res.data.code !== 200) {
              this.loginForm.captcha='';
              if(res.data.msg) {
                this.$message({
                  message: res.data.msg,
                  type: 'error',
                  customClass: 'zZindex',
                });
              }
            } else {
              this.$message({
                message: '短信发送成功，请注意短信接收',
                type: 'success',
                customClass: 'zZindex',
              });
              this.messageSendTime = 60
              this.timer = setInterval(() => {
                this.messageSendTime--
                if (this.messageSendTime === 0) {
                  clearInterval(this.timer)
                }
              }, 1000)
            }
          }).catch(() => {
          })
        }
      }
    },
    closeModal() {
      //路由重定向到：/home/index
      if (store.state.user.token) {
        this.getUserInfo()
      }
      router.push({
        path: '/home/index',
      })
      this.$emit('update:show', false);
    },
    handleLogin() {
      let rsaBefore = {}
      Object.assign(rsaBefore, this.loginForm)
      Object.keys(rsaBefore).forEach(key => {
        rsaBefore[key] = rsaService.encryptWithPublicKey(rsaBefore[key])
      })
      axios.post('/api/auth/webLoginPhone', rsaBefore).then(res => {
        if (res.data.code === 200) {
          this.$message({
            message: '登录成功',
            type: 'success',
            customClass: 'zZindex',
          });
          this.getUserInfo()
          store.commit('SET_TOKEN', res.data.data.access_token)
          setToken(res.data.data.access_token)
            this.showChoose = true
            document.addEventListener('click', this.toggleFullScreen, { once: true });   // 全屏
        } else {
          this.loginForm.phoneCode=''
          if(res.data.msg) {
            this.$message({
              message: res.data.msg,
              type: 'error',
              customClass: 'zZindex',
            });
          }
        }
      }).catch(() => {

      })
    },
    // 全屏
    toggleFullScreen() {
            const el = document.documentElement;
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                // 当前不是全屏状态
                if (el.requestFullscreen) {
                    el.requestFullscreen();
                } else if (el.webkitRequestFullscreen) { // Chrome等
                    el.webkitRequestFullscreen();
                } else if (el.msRequestFullscreen) { // IE/Edge
                    el.msRequestFullscreen();
                } else if (el.mozRequestFullScreen) { // Firefox
                    el.mozRequestFullScreen();
                }
            } else {
                // 当前是全屏状态
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                }
            }
        },
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
};
</script>

<style lang="scss" scoped>

::v-deep .phoneCodeV {
  input {
    border-right: none;
  }
}

.phoneCode {
  width: 80px;
  //padding: 10px 10px 10px 0px;
  display: flex;
  justify-content: center;
  border: none;
}

.enable {
  cursor: pointer;
}

.disable {
  cursor: not-allowed;
}
::v-deep .el-input-group__append {
  border: none;
}
::v-deep .el-input-group__append,
.el-input-group__prepend {
  color: #FFFFFF;
  background: #236CE0;
  font-size: 16px;
}

::v-deep .el-form-item__label {
  font-size: 16px;
  line-height: 48px;
}

::v-deep .el-input__inner {
  height: 48px;
  line-height: 48px;
}

::v-deep .verification_code .el-form-item__content {
  display: flex;
  align-items: center;
}

::v-deep .el-form-item {
  margin-bottom: 26px;
}

.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2024;
}

.choose-body {
  width: 543px;
  height: 450px;
  border-radius: 11px;
  background: url("@/assets/login/chooseBac.png");
  display: flex;
  flex-direction: column;
  align-items: center;

  .choose-body--title {
    margin-top: 38px;
    font-family: PingFangSC, PingFang SC;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }

  .choose-body--content {
    width: 373px;
    margin-top: 26px;
    display: flex;
    flex-direction: column;

    .normal-item {
      border: 1px solid #D5D5D5;
    }

    .choose-item {
      border: 1px solid #236CE0;
    }

    .choose-body--content--item {
      width: 373px;
      height: 102px;
      background: #F1F5FF;
      border-radius: 6px;
      cursor: pointer;
      background: rgba(62, 84, 102, 0.1);
      margin-bottom: 13px;
      display: flex;
      align-items: center;

      .left-item {
        margin-left: 22px;

        img {
          width: 66px;
          height: 66px;
        }
      }

      .right-item {
        display: flex;
        flex-direction: column;

        span {
          margin-left: 25px;
        }

        span:nth-child(1) {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 22px;
          color: #333333;
        }

        span:nth-child(2) {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          color: #7F7F7F;
        }
      }
    }
  }

  .chooseBtn-box {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 31px;

    .chooseBtn-item {
      width: 168px;
      height: 50px;
      border-radius: 5px;
      border: 1px solid #9D9D9D;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;
    }

    .chooseBtn-item:nth-child(1) {
      margin-right: 30px;
    }

    .chooseBtn-item:nth-child(2) {
      background: #236CE0;
      color: #FFFFFF;
    }
  }
}

.modal-container {
  background-color: #fff;
  border-radius: 11px;
  width: 560px;
  height: 474px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  .login_header {
    height: 85px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .login_header_context--item1 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #333333;
    }

    .login_header_context--item2 {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
    }
  }

  .login_divide {
    width: 561px;
    height: 1px;
    background-color: #E9E9E9;
    display: flex;
    align-items: center;
    justify-content: center;

    .login_divide-context {
      width: 93px;
      height: 2px;
      background-color: #236CE0;
    }
  }

  .login_form {
    width: 420px;
    height: 388px;
    margin-top: 30px;

    .verification_code {
      .verification_code--pic {
        width: 80px;
        height: 30px;
        margin-left: 11px;
      }

      .verification_code--flush-code {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        width: 42px;
        white-space: nowrap;
        text-decoration: underline;
        margin-left: 6px;
        color: #555555;
        cursor: pointer;
      }
    }

    .login_cert {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 10px;

      span {
        font-size: 14px;
      }

      .href_text {
        color: #4575C2;
        cursor: pointer;
      }
    }

    .login_btn {
      width: 100%;
      height: 50px;
      background: #236CE0;
      cursor: pointer;
      border-radius: 5px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
    }
  }

  .close_btn {
    color: #979797;
    font-size: 25px;
    cursor: pointer;
    font-weight: bold;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .close_btn:hover {
    color: #236CE0;
    transition: all 0.3s;
  }
}
</style>
